
<template>

</template>

<script>
    import useJwt from '@/auth/jwt/useJwt'
    export default {
        created() {
            useJwt.logout();

            this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
            this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' });
            this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });

        }
    };
</script>